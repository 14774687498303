<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <ion-list>
            <ion-item
              :router-link="{ name: p.routeName }"
              v-for="(p, i) in appPages"
              :key="i"
              class="link"
            >
              <ion-label class="ion-text-wrap">
                <h2>{{ p.title }}</h2>
                <p>{{ p.description }}</p>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
} from "@ionic/vue";

export default {
  components: {
    BaseLayout,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonLabel,
  },
  data() {
    return {
      title: "Template SMS",
      backLink: "communication",
      reloadable: false,

      appPages: [
        {
          title: "Absentee SMS",
          description:
            "Respected parents, your child {#var#} was absent on {#var#}. Kindly contact Principal",
          routeName: "sms.template.absentee",
        },
        {
          title: "Holiday SMS",
          description:
            "Respected Parents, school will remain closed on {#var#}",
          routeName: "sms.template.holiday",
        },
        {
          title: "Due SMS",
          description:
            "Your monthly school fee due upto {#var#} is INR {#var#}. Kindly visit the school and pay dues before {#var#}",
          routeName: "sms.template.due",
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
